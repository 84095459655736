<template>

	<teleport to="head">
		<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0">     
		<link rel="shortcut icon" type="image/png" href="/ushqo.png"/>
		<title> Ushqo </title>
	</teleport>

	<div v-if="isMobile"  class="menu" id="mobile">
		<button v-on:click="isMobileClicked = !isMobileClicked" id="menubutton"> Menu </button>
		<transition name="grow-down">
			<div v-if="isMobileClicked" class="buttonsexpanded">
				<button v-on:click="snapToPanel('#home')" :class="{activeMenu: activeMenu == 'home'}"> Home</button>
				<button v-on:click="snapToPanel('#cards')" :class="{activeMenu: activeMenu == 'cards'}"> Products</button>
				<button v-on:click="snapToPanel('#aboutus')" :class="{activeMenu: activeMenu == 'aboutus'}"> About us</button>
<!-- 				<button v-on:click="snapToPanel('#contact')" :class="{activeMenu: activeMenu == 'contact' || activeMenu == 'footer'}"> Contact </button> -->
			</div>
		</transition>
	</div>  
	<div v-else class="menu">
		<button v-on:click="snapToPanel('#home')" :class="{activeMenu: activeMenu == 'home'}"> Home</button>
		<button v-on:click="snapToPanel('#cards')" :class="{activeMenu: activeMenu == 'cards'}"> Products</button>
		<button v-on:click="snapToPanel('#aboutus')" :class="{activeMenu: activeMenu == 'aboutus'}"> About us</button>
<!-- 		<button v-on:click="snapToPanel('#contact')" :class="{activeMenu: activeMenu == 'contact' || activeMenu == 'footer'}"> Contact </button>
 -->	</div>

	<div id="center">
		<div id="home" class="slider">
			<!-- <img src="./assets/ushqo-contrast.png"> -->

			<!-- <div id="hometitle"> First AI-driven strategy coach for Esports. </div> -->
			<!-- <div id="homemore"> But, there is more <br> 🔰 </div> -->
			<div id="hometitle">
				<div id="main">Ushqo</div>
				<div id="innermore">
					Where innovation meets passion
					<br>
					<!-- <img src="./assets/ushqo-contrast.png">  -->
				</div>
			</div>
		</div>
		<div v-if="isMobile" v-for="(app, poistion) in appsList" :key="position" id="cards"  class="slider" >
			<div class="cardouter" :class="{ cardsnotvisible: !cards[position].visible }"  v-on:click="cards[position].clicked = !cards[position].clicked; changeCardsBackground(position) ">
				<transition-group name="flip">
					<div v-if="!cards[position].clicked" class="cardinner">
						<!-- <p v-if="!clicked[position]">{{app.description}}</p> -->
						<img v-bind:src="getImageUrl(app.img)">
					</div>
					<div v-else class="cardinner alt" >
						<h2>{{app.name}}</h2>
						<p>{{app.description}}</p>
					</div>
				</transition-group>
			</div>
		</div>

		<div v-else id="cards" :style="cardsBackground" class="slider">
			<transition-group name="slide-in-bottom">
				<div class="cardouter"
				v-for="(app, position) in appsList"
				@mouseenter="cards[position].clicked = true; changeCardsBackground(position)"
				@mouseleave="cards[position].clicked = false; changeCardsBackground(position)"
				:class="{ cardsnotvisible: !cards[position].visible }"
				v-show="cardsIntersecting" :key="position">

				<transition-group name="flip">
					<div v-if="!cards[position].clicked" class="cardinner" >
						<!-- <p v-if="!clicked[position]">{{app.description}}</p> -->
						<img v-bind:src="getImageUrl(app.img)">

					</div>
					<div v-else class="cardinner alt" >
						<h2>{{app.name}}</h2>
						<p>{{app.description}}</p>   
					</div>
				</transition-group>
			</div>
		</transition-group>

	</div>
	<div v-if="isMobile" id="aboutus" class="about slider">
		<div id="aboutusdetail">
			<h2> Our Origins </h2>
			{{ushqo}}
		</div>
	</div>
<!--   <div v-if="isMobile" id="aboutus" class="about slider">
    <transition name="slide-in-bottom">
      <div class="columns" v-show="aboutUsIntersecting">
	<div>
	  <img src="./assets/logo.png">
	  <h2> Michał, founder </h2>
	  {{michalDesc}}
	</div>
      </div>
    </transition>
  </div> -->
<!--   <div v-if="isMobile" id="aboutus" class="about slider">
    <transition name="slide-in-bottom">
      <div class="columns" v-show="aboutUsIntersecting">
	<div>
	  <img src="./assets/logo.png">
	  <h2> Grzegorz, co-founder </h2>
	  {{grzegorzDesc}}
	</div>
      </div>
    </transition>
  </div> -->
  <div v-else class="about slider" id="aboutus"> 
  	<div id="aboutusdetail">
  		<h2> Our Origins </h2>
  		{{ushqo}}
  	</div>
  			<div class="contactbox">
	  				<h2> Let's stay in touch </h2>
	  				<br>
	  				team(at)ushqo.com
	  				<br>
	  				<br>
	  			</div>
	<div id="footer">
		<div id="footer_in">
			<div class="socialink"></div>
	<div class="centerpiece">
			<div>
			</div>
			<span>Cooked with love in Lublin</span>	
			<small>&copy; Copyright 2021, Ushqo</small>
		</div>
		<div class="projects">
			<div class="project">	</div>
			<div class="project"> </div>
			<div class="project">	</div>
		</div>
	</div>
	<div id="footer_logo">
		<img src="./assets/ushqo-contrast.png" style="max-height: 90%; max-width: 90%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">
	</div>
</div>
</div>

<!-- <div class="about slider" id="contact">


				</div> -->
			</div>

		</template>


		<script>
import PanelSnap from 'panelsnap';
export default {

	name: 'Ushqo Panel',
	data() {
		return {
			cards: [
			{clicked: false,
				visible: true },
		{clicked: false,
			visible: true },
			{clicked: false,
				visible: true}],
			cardsBackground:{
				background: "rgb(233, 185, 137)",
				backgroundSize: "0%",
				backgroundRepeat: "repeat",
				backgroundPosition: "center",

				// borderRadius : "100vw",
				// paddingTop: "10vw",
				

				// opacity: "0.3",
				
			},
			cardsIntersecting: false,
			aboutUsIntersecting: false,
			footerIntersecting: false,
			appsList: [
			{"name": "Team Up",
			"img": "card_teamup.png",
			"description": "TeamUp - new way of connecting sport active people in your area. Have you ever wanted to play basketball but 1 on 1  won't cut it? Or quick full football match? TeamUp will be the answer. Not only it allows for quick groups gathering, it also solves the problem of reliability and ghosting, often taunted as the main problems of local sports activity. Due to the current situation, the release has been delayed. "
		},
		{"name": '"Project A"',
		"img": "card_dk.png",
		"description": '"Project A" the ultimate way to enjoy esports with the most complete fantasy sports experience of all the major leagues from around the world. Not only that, "Project A" provides valuable information for viewers as well as the professionals, with the most accurate data and the new trends.'
	},
	{"name": '"Project AI"',
	"img": "card_dkai.png",
	"description": '"Project AI" it is the premier piece of Machine Learning Algorithm working alongside the coaches in the professional esports teams, providing the edge to the winner\'s of tomorrow. In simpler terms, it will be Deep Blue of tactics in professional gaming industry.'
}		 
],
ushqo : "Ushqo, was founded in 2019 by a group of friends that wanted to create better, healthier social app for connecting sports enthusiasts in local areas. Then 2020 came and the pandemic forced us to pivot to our other great interest, esports. \n Since then we have developed the most complete fantasy experience for the most played game around the world, League of Legends. Even more, we are in advanced stages of AI coach for esports strategy, like AlphaGo for LoL strategy. \n\n The name \"ushqo\" originates from Polish \"uszko\" ~ small dumpling. It is always a part of every Christmas. Similarly, ushqo strives to be a part of daily routine of esports fans and professional players around the globe.",
michalDesc : "I have always enjoyed sports and esports is just one of the most interesting ones out there. Combining it with my love for Computer Science and AI, just lovely." ,
grzegorzDesc : "Well, I have been interested in League for quite some time and always took a lot of pleasure in understanding intricacies.",
activeMenu: '',
windowWidth: window.innerWidth,
isMobileClicked: false,
}
},
created() {


},
mounted() {
	this.simpleObserver("cardsIntersecting", "#home", 0.25);	
	this.simpleObserver("aboutUsIntersecting", "#cards", 0.25);
	this.simpleObserver("footerIntersecting", "#aboutus", 1);
	this.panelSnapInstance = new PanelSnap({
		panelSelector: ' > #app > #center > .slider',
		delay: 0,
		directionThreshold: 100,
		duration: 330,
	});
	this.panelSnapInstance.on('activatePanel', this.activateMenu);
	window.addEventListener('resize', this.resizeWindow);
},

unmounted(){
	window.removeEventListener('resize', this.resizeWindow);
	this.panelSnapInstance.destroy();
},


computed: {
	isMobile() {
		if((this.windowWidth < 1025 && window.innerHeight > window.innerWidth)
			|| this.windowWidth < 1000) return true;
			return false;		
	}
},

methods: {
	resizeWindow(){
		this.windowWidth = window.innerWidth;
	},
	getImageUrl(pic) {
		return require('./assets/'+pic);
	},
	changeCardsBackground(position){
		var context = this
		var state = this.cards[position].clicked	    
		if(state == false || this.isMobile){
			this.cardsBackground.background  = "#E9B989";
			this.cards.forEach(card => card.visible = true);
			return;
		}    
		switch(position) {
			
		// this.cardsBackground.background = "url(" + require('./assets/logo1.png')+")";
		
			case 0:
			case 1:
			case 2:
			this.cardsBackground.background = "url(" + require('./assets/ushqo-contrast.png')+") repeat";
			// this.cardsBackground.color = "red";
				this.cardsBackground.backgroundSize = "4%";
			
			break;
	}
	this.cards.forEach(card => card.visible = card.clicked);
},
imageLoadError(){
	return require('./assets/logo.png')
},
simpleObserver(intersecting, nametag, breaking) {
	let thresholds = [0, 0.25, 0.5, 0.75, 1];
	let options = {
		threshold: thresholds,
	}
	let self = this;
	const observer = new IntersectionObserver(entries => {
		const entry = entries[0];
		if (entry.intersectionRatio < breaking) {
			self[intersecting] = true;
		} else{
			self[intersecting] = false;
		}

	}, options);
	const entry = document.querySelector(nametag);
	observer.observe(entry);
},

snapToPanel(panelName){
	if(this.panelSnapInstance != null){
		var panel = document.querySelector(panelName);
		this.panelSnapInstance.snapToPanel(panel);
	}
},
activateMenu(panel){
	this.activeMenu = panel['id'];
}
},

}
</script>

<style>

	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,800&display=swap');
	html{
		scrollbar-width: none;
		font-size: calc(1vh + 1vw);
	}
	::-webkit-scrollbar {
		display: none;
	}
	#app {
		/*font-family: Avenir, Helvetica, Arial, sans-serif;*/
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-family: 'Open Sans', Helvetica, Arial, sans-serif;
		text-align: center;
		color: #2c3e50;
		height: 100%;
		overflow: hidden;
		scrollbar-width: none;  
		background: #E9B989; 
	}



/* Top Section */



.menu {
	display: flex;
	min-width: 100%;
	height: 6vh;
	position: fixed;
	z-index: 4;
	background: black;
}
.menu button{
	margin: auto;
	font-size: 1rem;
	color: white;

	width: 100%;
	transition: all 0.5s ease;
	height: 100%;

}

.menu#mobile{
	display: flex;
	flex-direction: column;
	min-width: 30%;
	position: fixed;
	top: 0;

	right: 0;
	height: auto;
}
.menu#mobile #menubutton{
	background: rgba(1,1,1,0.7) !important; 
	font-size: 1.25rem;
	color: white;
	width: 100%;
	margin: auto;
}
.menu#mobile .buttonsexpanded{
	display: flex;
	flex-direction: column;
	min-width: 30%;
	background: rgba(1,1,1,0.7) !important;    
}

.menu#mobile .buttonsexpanded button, .menu#mobile button{
	margin: auto;
	font-size: 1.25rem;
	color: white;
	height: calc(4vh + 4vw);
	width: 100%;
	transition: all 0.3s ease;
}

button.activeMenu{
	/*background: #FFDDBC;*/
	background: #E9B989;
	color: #000000;
	transition: all 0.5s ease;
	height: 100%;
}

.menu button:hover{
	background: #FFDDBC;
	color: #000000;
	transition: all 0.3s ease;
}


/* Links */
a{
	text-decoration: none;
	color: white;
}

/* Center Section */
#center{

}
.slider{
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	padding-top: 10vh;
	padding-left: 10vw;
	padding-right: 10vw;
	font-size: 1.5rem;
}
.slider div{

}

/* Image */

#home{
	background: #E9B989;
	position: relative;
	z-index: 3;
	display: flex;
	flex-direction: column;
}

#home #hometitle {
	position: relative;
	padding: 1vw;
	border-radius: 2vh;
	top: 70vh;
}
#home #hometitle:after{
	content: '';
	position: absolute;
	top:0px;
	left: 0px;
	width:100%;
	height:100%;
	padding: 1vw;
	border-radius: 2vh;
	z-index: -1;
	top: -70vh;
	opacity: 0.5;
	height: 80vh;
	background: url("./assets/ushqo-contrast.png");
	background-repeat: no-repeat;
	background-size: 62%;
	background-position: top;
} 
#home #homemore {
	font-size: 1.2rem;
	display: block;
	padding: 1.5vw;
	padding-bottom: 0.3vw;
	color: black;
	background: #FFDDBC;
	margin: auto;
	margin-bottom: 0;
	border-radius: 2vh;
}

#home #hometitle #innermore {
	border-radius: 2vh;
	padding: 10px;
}
#home #hometitle #main{
	/*background: #FFDDBC;*/

	padding-bottom: 20px;
	font-size: 2em;
	font-weight: bold;
/*		background: black;
		color: white;
		
		border-top-left-radius: 2vh;
		border-top-right-radius: 2vh;*/
	}
	#home #hometitle img{
		width: calc(2vw + 2vh);
		padding-top: 1vh;

	}


/* Cards */

#cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	height: 100vh;
	position: relative;
	z-index: 2;
	/* display: inline; */
}

#cards .cardouter{
	/* will-change: transform; */
	width: calc(12.5vw + 12.5vh);
	height: calc(20vw + 20vh);
	margin: auto 0;
}
#cards .cardouter:first-child {
}
#cards .cardouter:last-child {
}
#cards .cardouter.cardsnotvisible{
	visibility: hidden;
}
#cards .cardinner{
	border-radius: 10px;
	margin: auto;
	display: flex;
	background: navy;
	color: white;
	width: 100%;
	height: 100%;
	border: 1px solid black;
	overflow: hidden;
	/* position: absolute; */
} 
#cards .cardinner.alt{
	background: white;
	color: black;
	display: flex;
	flex-direction: column;
	font-size: 0.75rem;
}
#cards .cardinner.alt h2{

}

#cards .cardinner img{
	width: 100%;
	height: 100%;
	border-radius: 2px;
}
#cards .cardinner p{
	padding: 10px;
	text-align: justify;
}
#cards .cardinner.active{

}


/* About us */

.about{
	height: 100vh;   
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0;
}
.about .columns img{
	display: block;
	margin: calc(2vh + 2vw) auto;
	border-radius: 50%;
	background: black;
	width: 35%;
}

.about .columns div{
	max-width: 40%;
}
#aboutus #aboutusdetail{
	/*max-width: 90%;*/
	/*margin: auto auto calc(2vh + 2vw); */
	font-size: 0.8rem;
	white-space: pre-line;
	text-align: justify;
	padding-left: 15vw;
	padding-right: 15vw;
	padding-top: 10vw;
}
#aboutus #aboutusdetail h2{
	text-align: center;
}
.about .columns{
	display: flex;
	justify-content: space-evenly;
	height: 100%;
	font-size: 0.8rem;
}

.about .columns p{
	font-size: 0.75rem;
}

#contact{
	padding-left: 0;
	padding-right: 0;
	display: flex;
	flex-direction: column;
}
.contactbox{
	text-align: centre;
	padding-left: 2vh;
	padding-right: 2vh;
	font-size: 0.875rem;
}

/* Footer */

#footer{
	height: 13%;
	display: grid;
	position: relative;
	grid-template-rows: 1fr 2fr;
	/*margin-top: auto;*/
}

#footer_logo{
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 10;
	background-color: #FFDDBC;
	border: 0.75vh solid black;
	border-radius: 2vh;
	width: 15vh;
	height: 15vh;
	top: -8vh;
}

#footer_in{
	height: 100%;
	width: 100%;
	color: #FFDDBC;
	background: black;
	display: grid;
	grid-template-columns: 0% 40% 20% 40%;
	height: 100%;
	grid-row: 2;
}
#footer_in span{
	grid-column: 2;
	margin: auto;
}
#footer_in .socialink{
	display: flex;
	grid-column: 2; 
	grid-template-rows: 50% 50%;
	grid-template-columns: 50% 50%;
	font-size: 0.625rem;
}
#footer .docs{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 2fr 1fr 1fr;
	grid-column: 2 / 3;
}

#footer_in .socialink button{
	width: 100%; 
	height: 100%; 
	background-color: black; 
	color: white; 
	display: flex; 
	justify-content: center;
}

#footer_in .socialink button img{
	max-height: 42%;
	max-width: 22%;
	margin: auto 0;
}

#footer_in .socialink button a{
	margin: auto 0;
	padding: 0.25vw;
}

#footer_in .centerpiece{
	display: grid;
	grid-template-rows: 40% 30% 20%;
	grid-column: 3;

}
#footer_in .centerpiece div{
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;    
	grid-row: 1;
	grid-column: 1 / 5;
	margin-top: auto;
	margin-bottom: auto;

}
#footer_in .centerpiece span{
	grid-row: 2;
	grid-column: 2 / 4;
	font-size: 0.625rem;
}
#footer_in .centerpiece small{
	grid-row: 3;
	grid-column: 2 / 4;
	margin: auto;
	font-size: 0.5rem;
}
#footer_in .centerpiece div a{
	margin: auto;
	height: 100%;
	width: 100%;
}
#footer_in .projects{
	grid-column: 4;
	display: grid;    
	grid-template-columns: repeat(3, 1fr);
	height: 100%;
}
#footer_in .projects .project{
	display: grid;
	grid-template-rows: 1fr 2fr;
	margin: 0 1vw 0 1vw;
}
#footer_in .projects .project .title{
	/* padding-top: 2vh;	 */
	font-size: 0.8rem;
	grid-row: 1 / -1;
	color: #FFDDBC;
	width: 100%;
	/* height: 100%; */
	/* border-bottom: 0.1vh solid #FFDDBC; */
	margin: auto;
}
#footer_in .projects .project .project_details{
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
}
#footer_in .projects .project .project_details .content{
	margin: auto 0;
	font-size: 0.625rem;
}
#footer_in .projects .project .project_details_small{
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr 1fr 1fr;
}
#footer_in .projects .project .project_details_small .content{
	margin: auto 0;
}
#footer_in .projects .project .title:hover{
	background: #FFDDBC;
	color: black;
}
#footer_in .socialink a:hover, #footer_in .centerpiece div a:hover, #footer_in .projects .project .content:hover{
	color: #FFDDBC;
}

@media only screen and (min-device-width: 1025px), (orientation: landscape) {
	#footer_in .projects .project .project_details_small{
		display: none;
	}
}

@media only screen and (min-device-width: 601px) and (max-device-width: 1024px) and (orientation: portrait){
	.{
		overflow: hidden;
		font-size: 16px;
	}

	#cards{
		padding: 0;
	}
	#cards .cardouter:first-child {
		margin-left: 5vw;
	}
	#cards .cardouter:last-child {
		margin-right: 5vw;
	}
	#cards .cardouter{
		/* width: calc(30vw + 30vh); */
		/* height: calc(45vw + 45vh); */
		margin: auto !important;
		/*justify-content: space-evenly;*/
	}
	#cards .cardinner.alt{
		font-size: 1.5rem;
	}
	.menu{
		font-size: 2rem;
		background: none;
	}
	#aboutus #aboutusdetail{
		font-size: 1.4rem;
	}
	.about .columns{
		font-size: 1.2rem;
	}
	.about .columns div{
		max-width: 80%;
	}
	.contactbox{
		/* height: 87%; */
		overflow: hidden;
		font-size: 1.25rem;
	}	
	#footer_in .projects .project .project_details{
		display: none;
	}
	#footer_in{
		padding-left: 0;
		grid-template-columns: 0% 38% 24% 38%;
	}
	#footer_in .socialink{
		font-size: 1rem;
		margin-left: 5vw;
		/* grid-column: 1; */
		flex-direction: column;
		/* grid-template-columns: 55% 45%; */
	}
	#footer_in .socialink button{
		display: flex;
		max-height: 4vw;
		/* justify-content: left; */
	}
	#footer_in .centerpiece{
		grid-template-rows: 65% 10% 25%;
		/* grid-column: 2; */
	}
	#footer_in .centerpiece span{
		font-size: 0.75rem;
	}
	#footer_in .centerpiece small{
		font-size: 0.625rem;
	}
	#footer_in .projects{
		font-size: 0.75rem;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, 1fr);
		/* grid-column: 3; */
	}
	#footer_in .projects .project{
		grid-template-rows: 50% 50%;
		margin: 0 5vw;
	}
	#footer_in .projects .project .title{
		padding-top: 0.75vh;
		font-size: 1rem;
	}
}

@media only screen and (max-device-width: 600px), (max-device-height: 600px) {
	.{
		overflow: hidden;
		background: red;
	}
	#home #homemore {
		padding: 4.5vw;
	}
	#aboutus #aboutusdetail{
		font-size: 1.4rem;
	}
	#cards{
		flex-direction: column;
		padding: 0;

	}
	#cards .cardouter:first-child {
		/* margin-left: 5vw; */
	}
	#cards .cardouter:last-child {
		/* margin-right: 5vw; */
	}
	#cards .cardouter{
		width: calc(25vw + 25vh) !important;
		/* width: 40px; */
		margin: auto !important; 

		height: calc(40vw + 40vh) !important;
		/* max-width: 30px; */
		/* margin: auto !important; */
	}
	#cards .cardinner.alt{
		font-size: 1.5rem;
	}
	.menu{
		font-size: 2.5rem;
		background: none;
	}
	.about .columns{
		font-size: 1.6rem;
	}
	.about .columns div{
		max-width: 90%;
	}
	.about .columns img{
	}
	.contactbox{
		/* height: 70%; */
		overflow: hidden;
		font-size: 1.25rem;
	}
	#footer{
		height: 30%;
	}
	#footer_in .projects .project .project_details{
		display: none;
	}
	#footer_in{
		padding-left: 0;
		grid-template-columns: 0%30% 40% 30%;
	}
	#footer_in .socialink{
		font-size: 1rem;
		/* grid-template-columns: 1fr; */
		/* grid-template-rows: 25% 25% 25% 25%; */
		flex-direction: column;
		margin: 0 auto;
		grid-column: 1;
	}
	#footer_in .socialink button{
		display: flex;
		justify-content: left;
		margin-left: 3vw;
	}
	#footer_in .socialink button img{
		/* max-height: 33% !important; */
		/* max-width: 33% !important; */
		/* width: auto; */
		/* min-width: 20px; */
		margin: auto 0 !important;
	}
	#footer_in .centerpiece{
		grid-template-rows: 65% 10% 25%;

	}
	#footer_in .centerpiece span{
		font-size: 1rem;
	}
	#footer_in .centerpiece small{
		font-size: 0.75rem;
	}
	#footer_in .projects{
		font-size: 1rem;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(3, 1fr);
		/* grid-column: 3; */
	}
	#footer_in .projects .project{
		display: block;
		margin: auto;
	}
	#footer_in .projects .project .title{
		padding-top: 0;
		font-size: 1rem;
		border: none;
	}  
	#footer_in .projects .project .project_details_small{
		display: none;
	}
	#footer_logo{
		top: 5vh;
	}
}



/* Animations */




.flip-enter-active {
	transition: all 0.1s;
}

.flip-leave-active {
	display: none;
}

.flip-enter-from, .flip-leave-to {
	transform: rotateY(180deg);
	opacity: 0;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.slide-fade-enter-active {
	transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateX(20px);
	opacity: 0;
}

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
.bounce-enter-active {
	animation:  bounce-in-top 1.1s;
}
.bounce-leave-active {
	animation:  bounce-in-top 0.4s reverse;
}
.bounce-in-top {
	-webkit-animation: bounce-in-top 1.1s both;
	animation: bounce-in-top 1.1s both;
}
@-webkit-keyframes bounce-in-top {
	0% {
		-webkit-transform: translateY(-500px);
		transform: translateY(-500px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	38% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
	55% {
		-webkit-transform: translateY(-65px);
		transform: translateY(-65px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	72% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	81% {
		-webkit-transform: translateY(-28px);
		transform: translateY(-28px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	90% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	95% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}
@keyframes bounce-in-top {
	0% {
		-webkit-transform: translateY(-500px);
		transform: translateY(-500px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	38% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
	55% {
		-webkit-transform: translateY(-65px);
		transform: translateY(-65px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	72% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	81% {
		-webkit-transform: translateY(-28px);
		transform: translateY(-28px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	90% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	95% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}


.slide-in-enter-active, .slide-in-leave-active  {
	-webkit-animation: slide-in-fwd-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-fwd-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@-webkit-keyframes slide-in-fwd-top {
	0% {
		-webkit-transform: translateZ(-1400px) translateY(-800px);
		transform: translateZ(-1400px) translateY(-800px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0) translateY(0);
		transform: translateZ(0) translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-fwd-top {
	0% {
		-webkit-transform: translateZ(-1400px) translateY(-800px);
		transform: translateZ(-1400px) translateY(-800px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0) translateY(0);
		transform: translateZ(0) translateY(0);
		opacity: 1;
	}
}




.slide-in-bottom-enter-active, .slide-in-bottom-leave-active {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.slide-left-enter-active {
	animation:  slide-left 0.4s reverse;
}
.slide-left-leave-active {
	animation:  slide-left 0.4s;
}

.slide-left {
	-webkit-animation: slide-left 1s ease-in both;
	animation: slide-left 1s ease-in both;
}
@-webkit-keyframes slide-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(45vw);
		transform: translateX(45vw);
	}
}
@keyframes slide-left {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(45vw);
		transform: translateX(45vw);
	}
}

.grow-down-enter-active {

	animation: grow-down 300ms ease-in-out forwards;
	transform-origin: top center;
}
.grow-down-leave-active {
	animation: grow-down 300ms ease-in-out reverse;
	transform-origin: top center;
}

.grow-down {
	-webkit-animation: grow-down 300ms ease-in-out forwards;
	animation: grow-down 300ms ease-in-out forwards;
	transform-origin: top center;
}
@keyframes grow-down {

	0% {

		transform: scaleY(0)

	}

	80% {

		transform: scaleY(1.1)

	}

	100% {

		transform: scaleY(1)

	}

}
</style>
